import axios from "axios";
import { getAuth } from "firebase/auth";
import { komBaseURL } from "./constants";

// Function to get the current user's Firebase ID token
const getFirebaseToken = async () => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (currentUser) {
    return await currentUser.getIdToken();
  }
  return null;
};

// Axios request interceptor to attach Authorization header
const attachAuthToken = async (config: any) => {
  const token = await getFirebaseToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`; // Attach token
  }
  return config;
};

const komApiClient = axios.create({
  baseURL: komBaseURL,
});

// Attach the request interceptor to both clients
komApiClient.interceptors.request.use(attachAuthToken, (error) => {
  return Promise.reject(error);
});

export { komApiClient };
